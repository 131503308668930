<script setup lang="ts">

const props = defineProps({
  width: {
    type: Number,
    default: 24
  },
  height: {
    type: Number,
    default: 24
  }
})

const sizes = {
  width: `${props.width}px`,
  height: `${props.height}px`,
}

</script>

<template>
  <div :style="sizes" class="loader"></div>
</template>

<style lang="scss" scoped>

.loader {
  border: 2px solid #EAF0F6;
  border-radius: 50%;
  border-top: 2px solid var(--primary-blue-color);
  animation: spinner 4s linear infinite;
}

@keyframes spinner {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

</style>